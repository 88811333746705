import Container from './container'
import Link from 'next/link'
import Image from 'next/image'

export default function FooterAlphalives({ isIndex }) {
  return (
    <footer className="bg-color-dark-grey py-8 text-white">
      <Container>
        <div className="grid grid-cols-12 gap-4 mb-4 justify-between items-center">
          <div className="col-span-12 lg:col-start-3 lg:col-span-4">
            <ul>
                <li className="mb-4">
                    <Image width="185" height="40" src="/imgs/logo-alphalives.svg" alt="logo alphalives" />
                </li>
                <li>
                    <p className="color-white text-sm font-thin mb-4">5 rue Ponscarme <br/>75013 Paris</p>
                </li>
              <li className="text-color-orange font-thin text-sm">Vous avez un projet ?</li>
              <li className="text-color-orange font-thin mb-4">
                <Link href="tel:014-770-1974">
                  <a className="flex align-center text-sm">
                    <i className="fas svg-img fa-phone mr-4"></i>01 47 70 19 74
                  </a>
                </Link>
              </li>
            </ul>
          </div>
          {/*
          <div className="lg:col-span-4 col-span-12 lg:text-right lg:d-none">
            <p className="text-color-orange mb-4">Suivez nous sur les réseaux sociaux</p>
            <div className="flex lg:justify-end items-center"> 
                  <a target="_blank" className="text-color-orange mr-4" title="twitter" href="https://twitter.com/alphalives" rel="noreferrer noopener">
                    <i className="fab fa-twitter icon-fontawesome"></i> Twitter
                  </a>
                  <a target="_blank" className="text-color-orange mr-4" href="https://www.linkedin.com/company/alphalives-multim-dia" rel="noreferrer noopener">
                    <i className="fab fa-linkedin-in icon-fontawesome"></i> Linkedin
                  </a> 
            </div>
          </div>
          */}
        </div>

        <div className="grid grid-cols-12 gap-4 justify-between items-center">
          <div className="col-span-12 lg:col-span-4 lg:col-start-3">
            <p className="text-sm">©  Alphalives 2001 - 2021 | <Link href="/mentions-legales"><a className="font-thin text-color-orange">Mentions légales</a></Link></p>
          </div>
        </div>
       
      </Container>
    </footer>
  )
}
